<!-- eslint-disable max-len -->
<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="90" viewBox="0 0 100 90">
    <g fill="#FFF" fill-rule="nonzero">
      <path
        d="M48.9691 119.348C83.4157 119.348 111.34 85.8422 111.34 44.5109C111.34 3.17953 83.4157 -30.3261 48.9691 -30.3261C14.5224 -30.3261 -13.4021 3.17953 -13.4021 44.5109C-13.4021 85.8422 14.5224 119.348 48.9691 119.348Z"
        fill="url(#paint0_linear_1_2)"
      />
      <path
        d="M77.305 43.0616C74.2902 36.229 75.831 30.6021 78.7787 24.895C82.6643 27.3064 84.9421 30.8433 85.8801 35.2643C88.6938 35.7467 91.4405 35.9074 93.9864 36.7112C96.5321 37.515 96.8 38.6405 95.5272 41.3735C93.4504 45.875 89.8996 47.7238 85.8801 48.2061C84.5401 48.3669 84.0043 49.0902 83.5352 50.4569C80.3196 59.6205 75.764 67.5785 68.7296 73.1249C62.7673 77.7873 56.2019 80.1987 49.1675 80.6811C43.406 81.083 37.6446 81.0025 32.0841 78.8321C27.7966 77.2245 24.4468 74.2504 22.3031 69.4273C19.4224 63.0771 17.6135 56.3248 17.2785 49.0902C17.0775 45.3926 18.0155 44.1869 21.1642 44.1869C37.7786 44.1869 54.3931 44.1869 71.0075 44.1869C73.0172 44.1869 75.094 43.4635 77.4389 43.0616H77.305Z"
        fill="white"
      />
      <path
        d="M30.6613 79.971C25.9047 78.1222 22.488 74.8265 20.2772 70.0034C17.1955 63.1708 15.5207 56.3382 15.1187 49.1842C15.0517 47.657 15.1187 45.7277 16.0566 44.4415C16.1236 44.2808 16.2576 44.2004 16.3915 44.1201C15.3197 44.1201 14.2478 44.1201 13.1759 44.1201C10.0942 44.1201 9.0893 45.2454 9.2903 49.0234C9.62526 56.2579 11.434 63.0101 14.3147 69.3603C16.5256 74.1834 19.8752 77.0772 24.0959 78.7653C26.7756 79.8103 29.4554 80.3729 32.2021 80.6141C31.6662 80.4533 31.1302 80.2926 30.6613 80.0514V79.971Z"
        fill="white"
      />
      <path
        d="M22.8324 79.8834C18.0759 78.0346 14.6593 74.7389 12.4485 69.9158C9.36682 63.0832 7.69188 56.2506 7.29001 49.0966C7.22296 47.5692 7.29001 45.64 8.22785 44.354C8.36194 44.1932 8.42885 44.1128 8.56281 44.0323C7.55792 44.0323 6.48599 44.0323 5.48111 44.0323C2.39942 44.0323 1.39453 45.1578 1.59554 48.9359C1.9305 56.1704 3.73926 62.9225 6.62009 69.2727C8.83086 74.0958 12.1805 76.9896 16.4011 78.6777C18.9469 79.6422 21.5596 80.2049 24.1724 80.446C23.7034 80.2853 23.2344 80.1246 22.7655 79.9637L22.8324 79.8834Z"
        fill="white"
      />
      <path
        d="M47.5466 41.6264C44.8668 41.6264 43.527 40.0186 43.527 36.8034C43.527 32.2215 43.527 32.2215 47.3456 32.2215C52.1692 32.2215 51.9682 31.257 51.9682 37.6876C51.9682 40.3135 50.4944 41.6264 47.5466 41.6264Z"
        fill="white"
      />
      <path
        d="M37.5122 32.2102C42.2687 32.2102 41.5988 31.7278 41.6658 37.194C41.6658 41.6151 41.6658 41.6151 37.9142 41.6151C32.7557 41.6151 33.4256 41.9366 33.4256 36.7921C33.4256 31.8083 32.9566 32.2906 37.5793 32.2906L37.5122 32.2102Z"
        fill="white"
      />
      <path
        d="M27.4611 32.2369C31.8157 32.2369 31.4807 31.9154 31.4807 36.9795C31.4807 40.0877 30.1632 41.6418 27.5281 41.6418C22.4367 41.6418 23.2405 41.9633 23.2405 36.5776C23.2405 31.835 22.7046 32.2369 27.4611 32.2369Z"
        fill="white"
      />
      <path
        d="M57.7919 32.2119C62.4815 32.2119 62.0125 31.7297 62.0125 37.3564C62.0125 38.08 62.0125 38.8838 62.0125 39.6071C62.0125 40.9738 61.4096 41.5364 60.4047 41.5364C58.7299 41.5364 56.9881 41.5364 55.3131 41.5364C54.2413 41.5364 53.7723 40.8933 53.7723 39.5269C53.7723 37.9192 53.8393 36.3114 53.7723 34.7842C53.6383 32.7747 54.2413 31.9707 55.9161 32.1316C56.519 32.1316 57.1889 32.1316 57.7919 32.1316V32.2119Z"
        fill="white"
      />
      <path
        d="M72.2105 36.7905C72.1434 41.8546 72.9473 41.6939 68.1908 41.6939C63.5012 41.6939 63.9703 41.7743 63.9703 36.6298C63.9703 33.736 65.1537 32.2891 67.5209 32.2891C67.5209 32.2891 68.1238 32.2891 68.4587 32.2891C72.8134 32.2891 72.1434 32.0479 72.2105 36.7905Z"
        fill="white"
      />
      <path
        d="M47.5466 29.501C44.8668 29.501 43.527 27.8933 43.527 24.6781C43.527 20.0962 43.527 20.0962 47.3456 20.0962C52.1692 20.0962 51.9682 19.1316 51.9682 25.5622C51.9682 28.188 50.4944 29.501 47.5466 29.501Z"
        fill="white"
      />
      <path
        d="M37.5628 29.501C34.8831 29.501 33.5433 27.8933 33.5433 24.6781C33.5433 20.0962 33.5433 20.0962 37.3619 20.0962C42.1855 20.0962 41.9845 19.1316 41.9845 25.5622C41.9845 28.188 40.5105 29.501 37.5628 29.501Z"
        fill="white"
      />
      <path
        d="M57.7919 20.0868C62.4815 20.0868 62.0125 19.6044 62.0125 25.2313C62.0125 25.9548 62.0125 26.7586 62.0125 27.482C62.0125 28.8486 61.4096 29.4112 60.4047 29.4112C58.7299 29.4112 56.9881 29.4112 55.3131 29.4112C54.2413 29.4112 53.7723 28.7682 53.7723 27.4017C53.7723 25.7939 53.8393 24.1863 53.7723 22.6591C53.6383 20.6494 54.2413 19.8456 55.9161 20.0063C56.519 20.0063 57.1889 20.0063 57.7919 20.0063V20.0868Z"
        fill="white"
      />
      <path
        d="M57.7919 8.10763C62.4815 8.10763 62.0125 7.62528 62.0125 13.2522C62.0125 13.9755 62.0125 14.7793 62.0125 15.5029C62.0125 16.8693 61.4096 17.4321 60.4047 17.4321C58.7299 17.4321 56.9881 17.4321 55.3131 17.4321C54.2413 17.4321 53.7723 16.789 53.7723 15.4224C53.7723 13.8148 53.8393 12.2072 53.7723 10.6798C53.6383 8.67027 54.2413 7.86645 55.9161 8.02718C56.519 8.02718 57.1889 8.02718 57.7919 8.02718V8.10763Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DockerComposeIcon',
};
</script>
